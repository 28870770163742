.video-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
  
  @media (min-width: 640px) { /* Small screens (e.g., tablets) */
    .video-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 768px) { /* Medium screens */
    .video-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1024px) { /* Large screens (desktop) */
    .video-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .video-item {
    background-color: #f9fafb;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  