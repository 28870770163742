/* General Styles */
.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 30px;
  height: auto; /* Auto height for dynamic resizing */
  background-color: rgba(0, 0, 0, 0.02);
  width: 90%;
  border-radius: 8px;
  margin: 7vh auto;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.sub-container1,
.sub-container2 {
  width: 48%; /* Adjust width to accommodate wrapping */
  padding-top: 1%;
}

.vertical-divider {
  width: 0.5px;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.searchTitle {
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  position: relative;
}

.searchTitle::after {
  content: '';
  display: block;
  width: 50%;
  height: 4px;
  background: #37afe1;
  position: absolute;
  bottom: -5px;
  border-radius: 2px;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.searchTitle:hover::after {
  height: 6px;
  opacity: 1;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.searchInput {
  flex-grow: 1;
  height: 7vh;
  border-radius: 15px;
  padding-left: 2%;
  font-size: 1.1rem;
  color: #333;
  border: 2px solid #d1d5db;
  outline: none;
  transition: all 0.3s ease;
  background: linear-gradient(to right, #f0f9ff, #c0e1ff);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.searchInput:hover {
  border-color: #37afe1;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.searchInput:focus {
  border-color: #37afe1;
  background: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.searchIcon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.5rem;
}

.listHomeButton:hover {
  background-color: #2b7a9f;
  transform: translateY(-2px);
}

.listHomeInfo {
  color: rgba(0, 0, 0, 0.7);
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
  margin-top: 1rem;
  text-align: left;
  line-height: 1.5;
}

.listHomeInfo a {
  color: #37afe1;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.listHomeInfo a:hover {
  color: #2b7a9f;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .container {
    flex-direction: column; /* Stack the containers vertically */
    align-items: center;
    padding: 0 1vw;
  }

  .sub-container1,
  .sub-container2 {
    width: 100%; /* Full width for smaller screens */
  }

  .vertical-divider {
    display: none; /* Hide the vertical divider on smaller screens */
  }

  .listHomeButton {
    width: 40%; /* More width for smaller screens */
  }

  .searchInput {
    width: 100%; /* Full width on small screens */
  }
}

@media (max-width: 768px) {
  .searchTitle {
    font-size: 1.2rem; /* Reduce title size on smaller screens */
  }

  .searchInput {
    font-size: 1rem; /* Adjust font size */
    height: 6vh; /* Adjust input height */
  }

  .listHomeButton {
    width: 50%; /* Larger button width for smaller screens */
  }
}

@media (max-width: 480px) {
  .searchInput {
    font-size: 0.9rem;
    height: 5vh;
  }

  .listHomeButton {
    width: 60%; /* Full width for the button */
    font-size: 0.9rem; /* Adjust font size */
    height: 5vh; /* Adjust button height */
  }

  .searchTitle {
    font-size: 1rem; /* Adjust title size */
  }

  .listHomeInfo {
    font-size: 0.8rem; /* Adjust supporting text size */
  }
}
