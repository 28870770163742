.categorys-container {
  width: 100%;
  margin: 5vh auto;
  position: relative;
  padding: 0 7vw 0 7vw;
}
.category-subContainer {
  width: 100%;
}

.category-desc {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  margin: 2vh 0 2vh 0;
  letter-spacing: 0.5px;
  width: 50vw;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.category-heading {
  text-align: left;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  color: #475569;
  font-weight: 700;
  padding-bottom: 35px;
}

.category-text {
  color: #37afe1;
}

.category-separator {
  width: 100px;
  height: 2px;
  background: #37afe1;
  margin-top: 16px;
}

.category-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 12px;
}

.category-image-container:hover {
  transition: 0.3s ease-in;
}

.category-image {
  width: 100%;
  height: 15vh;
}

.category-name {
  position: absolute;
  bottom: 6px;
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  font-family: 'Oxanium', sans-serif;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-name-bg {
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@media (max-width: 750px) {
  /* md */
  .category-heading {
    text-align: left;
  }
  .category-desc {
    text-align: left;
    width: 100%;
  }
  .category-subContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
