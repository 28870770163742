.banner-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
}

.banner-wrapper {
  position: relative;
}

.banner-image {
  width: 100%;
  height: auto;
}

.bannerHeadingContainer {
  margin-top: 5vw;
  cursor: pointer;
}
.bannerHeading {
  font-size: 3.2rem;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 35vw;
  font-weight: 600;
  z-index: 1;
  text-shadow: 1px 1px 1px black;
}

.bannerDetail {
  width: 40vw;
  margin-top: 1vh;
  line-height: 4vh;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
}

.bannerButton {
  padding: 10px 20px;
  background-color: #ff4500;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
}

.bannerButton:hover {
  background-color: #ff6347;
}

.bannerSubContainer {
  display: flex;
  justify-content: space-around;
}
.product-grid {
  margin-top: 5vh;
}
/* @media (max-width: 900px) {
    .bannerSubContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-grid{
        display: none;
    }
    .bannerHeading{
        width: 50vw;
        text-align: center;
        font-size: 1.6rem;
        color: white;
    }
    .bannerDetail{
        width: 50vw;
        font-size: 1.2rem;
        text-align: center;
        line-height: 4vh;
        color: black
    }
} */
@media (max-width: 850px) {
  .bannerHeading {
    width: 40vw;
    font-size: 2.3rem;
    margin-top: 4rem;
  }
}
@media (max-width: 650px) {
  .bannerHeading {
    width: 70vw;
    text-align: center;
    font-size: 2.4rem;
    margin-top: 4rem;
    color: black;
    text-shadow: 1px 1px 1px white;
  }
  .bannerDetail {
    width: 70vw;
    font-size: 1.1rem;
    text-align: center;
    line-height: 4vh;
    text-shadow: 1px 1px 1px black;
  }
  .product-grid {
    display: none;
  }
}
