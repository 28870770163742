.featuringContainer {
  width: 100%;
  margin: 10vh auto;
  padding: 0 7vw 0 7vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.productContainer {
  width: 100%;
  position: relative;
  height: 45vh;
  margin-left: 5%;
}

.heading {
  text-align: left;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  color: black;
  font-weight: 700;
}
.headingDesc {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  margin: 2vh 0 2vh 0;
  letter-spacing: 0.5px;
  width: 40vw;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
.headingText {
  color: #37afe1;
}

.listHomeButton {
  width: 25%; /* Change to percentage to be responsive */
  height: 7vh;
  border: none;
  border-radius: 15px;
  background-color: #37afe1;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
  margin-left: 3%;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.productCard {
  position: absolute;
  width: 45%;
  height: 30%;
  transition: transform 0.3s ease;
}

.productCard:nth-child(1) {
  top: 50px;
  left: 0;
}

.productCard:nth-child(2) {
  top: 0;
  left: 45%;
}

@media (max-width: 1030px) {
  /* md */
  .productContainer {
    position: relative;
    margin-left: 12%;
  }
  .productCard {
    width: 30vw;
    height: 24vw;
    margin-left: 10%;
  }
  .productCard:nth-child(1) {
    top: 0;
    left: 0;
  }

  .productCard:nth-child(2) {
    top: 23rem;
    left: 0%;
  }
}

@media (min-width: 1030px) and (max-width: 1300px) {
  /* md-lg */
  .productContainer {
    grid-template-columns: repeat(3, 1fr); /* md-lg:grid-cols-3 */
    margin-top: 5%;
  }

  .productCard {
    width: 20vw;
    height: 20vw;
    margin-left: 10%;
  }
}

@media (max-width: 750px) {
  /* sm */
  .featuringContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: -15%;
  }
  .productContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110vh;
    margin-left: 0;
  }
  .heading {
    text-align: left;
    width: 100%;
  }
  .headingDesc {
    text-align: left;
    width: 100%;
  }
  .productCard {
    width: 80vw;
    height: 60vw;
    animation: none;
    position: relative;
    margin-left: 0;
  }
  .productCard:nth-child(1) {
    top: 0;
    left: 0;
  }

  .productCard:nth-child(2) {
    top: 3rem;
    left: 0;
  }
}

@media (max-width: 500px) {
  /* sm */
  .productCard:nth-child(2) {
    top: 10rem;
    left: 0;
  }
}
