.button-style {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #3b82f6; /* Blue color */
  color: white;
  border-radius: 0.25rem;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-style:hover {
  background-color: #2563eb; /* Darker blue */
  text-decoration: none;
}
