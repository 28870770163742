.login-container {
  padding: 2.5rem;
  background-color: #f1f5f9;
  margin: 1rem auto 0 auto;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  display: flex;
  flex-direction: column; /* Stacks form and image vertically */
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.375rem;
}

.form-section {
  padding: 2rem;
  width: 100%; /* Full width */
}

.form-title {
  text-align: center;
  font-size: 1.5rem;
  color: #64748b;
  font-weight: bold;
}

.form {
  color: #64748b;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  outline: none;
  border-radius: 0.375rem;
  transition: border-color 0.2s;
}

.form-input:focus {
  border-color: #0ea5e9;
}

.submit-button {
  width: 100%; /* Full width */
  padding: 0.75rem;
  background-color: #0ea5e9;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  color: #fff;
  border-radius: 0.375rem;
  transition: box-shadow 0.2s;
}

.submit-button:hover {
  box-shadow: 0 1rem 1.5rem rgba(16, 185, 129, 0.4);
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
}

.separator-line {
  height: 1px;
  background-color: #e2e8f0;
  width: 100%;
}

.separator-text {
  padding: 0 0.75rem;
  color: #64748b;
}

.external-link {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #02e3e0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  margin-bottom: 0.75rem;
  transition: box-shadow 0.2s;
}

.external-link:hover {
  box-shadow: 0 1rem 1.5rem #FFD700;
}

.seller-login {
  background-color: #FFD700;
}

.image-section {
  padding: 1rem;
  display: none; /* Hidden by default for smaller devices */
}

.image {
  width: 100%;
  height: auto;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .login-form-container {
    flex-direction: row; /* Place form and image side by side */
  }

  .form-section {
    width: 70%; /* Take half the width */
  }

  .image-section {
    display: block; /* Show image for larger screens */
    width: 30%;
  }
}

@media (max-width: 480px) {
  .form-title {
    font-size: 1.25rem; /* Adjust title size */
  }
  .form-section {
    width: 100%;
    /* Take half the width */
  }

  .separator-text {
    font-size: 0.9rem; /* Adjust separator text size */
  }

  .external-link {
    padding: 0.75rem; /* Increase padding for external links */
  }
  .login-form-container {
    display: flex;
    align-items: center;
    background-color: #f1f5f9;
  }

  .form-section {
    width: 20rem;
    padding: 1rem;
  }

  .form-input {
    width: 100%;
  }
  .login-container {
    padding: 0;
  }
}
