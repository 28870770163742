.product-card {
  border: 1px solid #e5e7eb; /* Tailwind's border color */
  transition: all 0.5s;
  cursor: pointer;
  height: 40vh;
  border-radius: 5%;
  background-color: whitesmoke;
}

.product-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Similar to Tailwind's shadow-md */
  transform: translateY(-3px); /* Similar to Tailwind's -mt-3 */
  background-color: #37afe1;
}

.product-image-container {
  position: relative;
  overflow: hidden;
}

.discount-badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #37afe1; /* Tailwind's red-500 */
  color: white;
  font-weight: 600;
  font-size: 0.875rem; /* Tailwind's text-xs */
  left: 0.5rem; /* Tailwind's left-2 */
  top: 0.5rem; /* Tailwind's top-2 */
}

.product-image {
  width: 100%;
  height: 25vh;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  object-fit: cover;
}

.action-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.7s;
}

.product-card:hover .action-icons {
  bottom: 0.75rem;
}

.action-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  transition: all 0.3s;
  border: none;
}

.action-icon:hover {
  background-color: #024caa; /* Tailwind's custom color */
  color: white;
  transform: rotate(720deg);
}
.product-info {
  display: flex;
  justify-content: space-between;
  padding: 0 1vw 0 1vw;
  margin: 1vh 0 1vh 0;
}

.product-name {
  width: 10vw;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Add
}


.product-price {
    font-size: 1rem; /* Tailwind's text-md */
  font-weight: 600;
}
.rating-container {
  display: flex;
  align-items: center;
  padding: 0 0 0vw 1vw;
  margin: 1vh 0 1vh 0;
}

.star {
  margin-right: 4px; /* Add spacing between stars */
  color: #ffcc00; /* Example star color */
  font-size: 1.5rem; /* Adjust size as needed */
}
@media (max-width: 1030px) {
  /* sm */
  .product-name {
    width: 15vw;
  }
}
@media (max-width: 640px) {
  /* sm */
  .product-name {
    width: 25vw;
  }
}
