.map-heading {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.heading h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.usa-map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.svg-container {
  width: 100%;
  max-width: 800px;
  height: auto;
  position: relative;
}

#usa {
  width: 100%;
  height: auto;
}

.state {
  fill: #e0e0e0;
  stroke: #ffffff;
  stroke-width: 0.5;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.serviceable {
  fill: #37afe1;
}

.state:hover {
  fill: #37afe1;
  transition: fill 0.3s ease;
}

.state:active {
  fill: #ff5722;
}
