.link-hover {
  position: relative;
  transition: color 0.3s ease; /* Smooth transition for color */
}

.link-hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: transparent; /* Initially no underline */
  transition: background 0.3s ease; /* Smooth transition for underline */
}

.link-hover:hover {
  color: #37afe1; /* Change text color on hover */
}

.link-hover:hover::after {
  background: #37afe1; /* Show underline color on hover */
}
